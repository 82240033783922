const FESTIVAL_DAYS = [
  { id: 0, value: "*", label: "All" },
  { id: 1, value: "2025-03-07", label: "Friday, March 7th" },
  { id: 2, value: "2025-03-08", label: "Saturday, March 8th" },
  { id: 3, value: "2025-03-09", label: "Sunday, March 9th" },
  { id: 4, value: "2025-03-10", label: "Monday, March 10th" },
  { id: 5, value: "2025-03-11", label: "Tuesday, March 11th" },
  { id: 6, value: "2025-03-12", label: "Wednesday, March 12th" },
  { id: 7, value: "2025-03-13", label: "Thursday, March 13th" },
  { id: 8, value: "2025-03-14", label: "Friday, March 14th" },
  { id: 9, value: "2025-03-15", label: "Saturday, March 15th" },
];
export default FESTIVAL_DAYS;
